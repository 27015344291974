import type { SubmissionV2 } from '~/types/submissionV2'

export function submissionIsRead(submission: SubmissionV2) {
  if (submission.status === 'Se') return true

  if (submission.last_read_agency && submission.update_timestamp) {
    const lastReadTime = new Date(submission.last_read_agency).getTime()
    const updateTime = new Date(submission.update_timestamp).getTime()

    return updateTime < lastReadTime
  }
  return false
}
